import React from 'react';
import {
  QuickLinkButton,
} from "@components/buttons"

const GlobalQuickLinks = props =>  {
  return (
        <div>
          <h2>Mastodon Stuff</h2>
          <p>
            <QuickLinkButton
              content={"Get A RecordPlug.Club Account for Mastodon"}
              url={"https://recordplug.club/"}
              icon={"file circle"}
            />
          </p>
          <p>
            Or you can find me here on <a rel="me" href="https://recordplug.club/@jimmyether">Mastodon</a>.
          </p>
          <h2>Bands & Studio Projects</h2>
          <p>
            <QuickLinkButton
              content={"Loud Humans on Spotify"}
              url={"https://open.spotify.com/artist/5IM4lR9kZq1zlbNJzRMXmt"}
              icon={"play circle"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Victory Hands on Spotify"}
              url={"https://open.spotify.com/artist/0OE2X0K98KpV9SjESoeXJc"}
              icon={"play circle"}
            />
          </p>
          <h2>Podcast</h2>
          <p>
            <QuickLinkButton
              content={"Striking Kooks with Jimmy Ether Podcast"}
              url={"https://strikingkooks.com"}
              icon={"play circle"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Apply to be a podcast guest"}
              url={"https://docs.google.com/forms/d/e/1FAIpQLScj5rsNiEojx5HMo1OgPkXuNKvZDbeJZJaawTmV9VSlHwZKgQ/viewform"}
              icon={"file text"}
            />
          </p>
          <h2>Recent Articles and Videos</h2>
          <p>
            <QuickLinkButton
              content={"LUFS and The Loudness War"}
              url={"/lufs-and-the-loudness-war/"}
              icon={"play circle"}
            />
          </p>
          <h2>Incomplete Reel of My Production Work</h2>
          This one has complete albums of what I can find on Spotify.<br />
          <p>
            <QuickLinkButton
              content={"Spotify Playlist"}
              url={"https://open.spotify.com/playlist/7BeDbrKeli9S69ztLLwzKn?si=15c2bcaae65845d0"}
              icon={"play circle"}
            />
          </p>
          <p>
            This one is 12 years old, but has selections not on Spotify.<br />
            <QuickLinkButton
              content={"Soundcloud Selections"}
              url={"https://soundcloud.com/headphonetreats/sets/jimmyether-prod-reel"}
              icon={"play circle"}
            />
          </p>
          <h2>Live Streaming Social Media</h2>
          <p>
            <QuickLinkButton
              content={"YouTube"}
              url={"https://www.youtube.com/channel/UC_wJrTHrWsYY3VvNxYMkBfg"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"TikTok"}
              url={"https://www.tiktok.com/@jimmyether"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Twitch"}
              url={"https://www.twitch.tv/jimmyether"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Facebook"}
              url={"https://www.facebook.com/ear4audio"}
              icon={"video"}
            />
          </p>
          <p>
            <QuickLinkButton
              content={"Twitter"}
              url={"https://twitter.com/jimmyether"}
              icon={"video"}
            />
          </p>
        </div>
  );
}

export default GlobalQuickLinks

GlobalQuickLinks.defaultProps = {
  textPadded: false,
  text: "",
}